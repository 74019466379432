import { Box, Container, Text, useColorMode } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import BannerBlepharo from "../../components/choc/BannerBlepharo"
import BannerVeoJadeklinik from "../../components/choc/BannerVeoJadeklinik"
import LogoHeadlineTeaser from "../../components/choc/LogoHeadlineTeaser"
import LogoHeadlineTeaserVeo from "../../components/choc/LogoHeadlineTeaserVEO"
import QuadTeaser from "../../components/choc/QuadTeaser"
import ScrollcompontentListelement from "../../components/choc/ScrollComponentListElement"
import TwoElementsBoHu from "../../components/choc/TwoElementstBoHu"
import ImgTextModule from "../../components/ImgTextModule"
import Layout from "../../components/layout/LayoutA"
import FadeInAnimation from "../../DefinitionComponents/Animation-Components/FadeInAnimation"
import ImgMaskReveal from "../../DefinitionComponents/Animation-Components/ImgMaskReveal"
const Blank = () => {
  let { toggleColorMode, ColorMode, setColorMode } = useColorMode()
  useEffect(() => {
    setColorMode(colormodeValue)
  })
  const [viewportHeight, setViewportHeight] = useState(0)
  const [pageHeight, setPageHeight] = useState(0)
  useEffect(() => {
    setViewportHeight(window.innerHeight)
    setPageHeight(document.body.clientHeight)
    // console.log('VIEWPORTHEIGHT: ' + window.innerHeight)
    // console.log('PAGEHEIGHT: ' + document.body.clientHeight)
  }, [])
  const [colormodeValue, changeColorModeValue] = useState("light")
  return (
    <Layout

        title={"Ästhetik und Schönheitsoperationen in Wilhelmshaven | VeoMed"}
        description={"Faltenreduktion und -relaxation – mit Hyaluron und Botulinum. Schönheits-OP's, Behandlungen und Korrekturen: JadeKlinik in Wilhelmshaven."}
    >
      <ImgTextModule
        src={
          "285358_Woman_Model_Light_Beam_Naked_By_Hans_Peter_Schepp_Artlist_HD.mp4"
        }
        delay={0.3}
        duration={0.5}
        threshold={0.3}
        initialY={10}
        initialX={10}
        video={true}
        rounded="none"
        h="76vh"
        px={{ base: 4, md: 12, lg: 4, xl: 0 }}
        mediaOpacity={1}
        bgColor="brand.gray.100"
      >
        <Box p={{ base: 0, lg: "8" }}>
          <FadeInAnimation
            threshold={0.4}
            delay={0.4}
            duration={0.7}
            initialX={0}
            initialY={-10}
          ></FadeInAnimation>
          <FadeInAnimation
            threshold={0.4}
            delay={0.4}
            duration={0.7}
            initialX={0}
            initialY={-10}
          >
            <Text
              color="brand.tan.ulight"
              fontWeight="normal"
              fontSize={{ base: "3xl", lg: "6xl" }}
            >
              Faltenreduktion und -relaxation
            </Text>
          </FadeInAnimation>

          <FadeInAnimation
            threshold={0.4}
            delay={1}
            duration={0.7}
            initialX={0}
            initialY={10}
          >
            <Text
              color="brand.tan.vlight"
              mb={8}
              maxW={{ base: "100%", lg: "50%" }}
              fontSize={{ base: "2xl", lg: "4xl" }}
            >
              mit Hyaluron und Botulinum
            </Text>
          </FadeInAnimation>
          <Text color="brand.tan.vlight" fontSize="xl">
            Hyaluron: Filler – Unterspritzung von Falten zum Volumenaufbau.
            <br />
            Botulinum: Relaxation – Verhindern von Faltenbildung.
          </Text>
        </Box>
      </ImgTextModule>
      <LogoHeadlineTeaser
        py={24}
        headline="Vorteile"
        subheadline="Minimalinvasiv und Wirksam
        "
      />

      <ImgMaskReveal
        imgsrc={"andrey-zvyagintsev-T4bFs7q9E94-unsplash.jpg"}
        initScale={1}
        maxH={700}
        duration={1}
        delay={1}
      />

      <Container variant="layoutContainer" mt={0.5} py={12} mb={0.5}>
        <ScrollcompontentListelement
          sizevariant="wide"
          title="Kombination"
          text1="Die Zornesfalte"
          text2="
        Wir verwenden Hyaluron und Botulinum gern auch in Kombination. Insbesondere bei der sogenannten »Zornesfalte« ist ein kombinierter Ansatz angeraten. 
"
        />
        <ScrollcompontentListelement
          sizevariant="wide"
          title="Botulinum"
          text1="Leichtere Zornesfalten, Lippenfalten, Krähenfüße und Stirnfalten"
          text2="
          Wir injizieren Botox, um den Muskeltonus zu senken und der Haut die Gelegenheit zur Erholung, Entspannung und »Entfaltung« zu geben.          "
        />

        <ScrollcompontentListelement
          sizevariant="wide"
          title="Hyaluron"
          text1="Lippenunterspritzung"
          text2="Hyaluron setzen wir als Filler bei Lippenunterspritzungen ein. Die Herausforderung für den Behandler ist die anatomisch korrekte Verteilung des Fillers in der Ober- und Unterlippe, um die natürlichen Proportionen zu betonen.

"
        />
      </Container>
      <Container variant="layoutContainer" mt={0.5} py={12} mb={0.5}>
        <TwoElementsBoHu />
      </Container>
      <BannerBlepharo />
      <Container variant="layoutContainer" mt={0.5} py={12} mb={0.5}>
        <BannerVeoJadeklinik />
      </Container>
      {/* <LogoHeadlineTeaserVeo
        headline="Ästhetik mit High-Tech"
        subheadline="Und Verantwortung"
      /> */}
      <QuadTeaser />
    </Layout>
  )
}

export default Blank
