import { Box, Button, Text, Heading } from "@chakra-ui/react"
import { Link } from "gatsby"
import { motion, AnimatePresence } from "framer-motion"
import React from "react"

export const Modal2 = ({ isToggled, setToggle, children }) => {
  return (
    <AnimatePresence>
      {isToggled && (
        <motion.div
          position="relative"
          top="250px"
          left="25%"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          mt={"25vh"}
        >
          <Box
            w={{ base: "80vw", md: "50vw" }}
            mt={"35vh"}
            as={motion.div}
            position="fixed"
            zIndex="99999"
            initial={{ y: 50, scale: 0.2 }}
            animate={{ y: 0, scale: 1 }}
            exit={{ y: -50, scale: 0.1 }}
            p={8}
            top={"0"}
            boxShadow="base"
            bg={"#ffffff"}
            borderRadius="11px"
          >
            <Heading color="brand.blue">Botulinum</Heading>
            <Text>
              Botulinum blockiert – hochselektiv – die Rezeptor stelle des
              Muskels für den Neurotransmitter Acetylcholin.
              <br />
              Diese Rezeptoren am Muskel werden nach 3-4 Monaten komplett
              ausgetauscht gegen neue Rezeptoren.
              <br />
              Damit verflüchtigt sich die Wirkung und muss dann erneuert werden.
              <br />
              Der Muskel, der für die Faltenbildung verantwortlich ist, und den
              wir durch die Behandlung schwächen, kann seiner 'Faltenwirkung'
              nicht mehr nachkommen.
              <br />
              Bei regelmäßiger und langfristiger Anwendung wird das
              Behandlungsintervall länger, da sich der betreffende Muskel abbaut
              (atrophiert).
            </Text>
            <Button
              mt={12}
              px={6}
              pt={3}
              pb={4}
              fontSize="xs"
              textAlign="center"
              variant={"solid"}
              onClick={() => setToggle(false)}
            >
              Schliessen
            </Button>
          </Box>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
