import { Box } from "@chakra-ui/react"
import React, { useState } from "react"

import FadeInAnimation from "../../DefinitionComponents/Animation-Components/FadeInAnimation"
import RouterLink from "../../DefinitionComponents/Utility/Routing/RouterLink"
import { Grid, GridItem, Text, Button } from "@chakra-ui/react"
import ImgTextModule from "../ImgTextModule"
import { Modal1 } from "./Modal1"
import { Modal2 } from "./Modal2"
function TwoElementsBoHu() {
  const [isToggledR, setToggleR] = useState(null)
  const [isToggledL, setToggleL] = useState(null)
  return (
    <>
      <Grid
        px={{ base: 6, md: 12, lg: 6, xl: 0 }}
        templateColumns={{
          base: "repeat(6, 1fr)",
          md: "repeat(6 , 1fr)",
          lg: "repeat(12, 1fr)",
          xl: "repeat(12, 1fr)",
        }}
        gap={{ base: 0, md: 0, lg: 8 }}
        my={0}
      >
        <GridItem colSpan="6" mb={8}>
          <ImgTextModule
            src={
              "343869_Waterdrops_Splashing_Body_Portrait_By_Ira_Belsky_Artlist_HD.mp4"
            }
            delay={0.3}
            h="100%"
            duration={0.5}
            threshold={0.3}
            initialY={10}
            initialX={10}
            video={true}
            mediaOpacity={0.9}
            bgColor="brand.gray.vdark"
          >
            <Box p={{ base: 0, lg: "8" }}>
              <FadeInAnimation
                threshold={0.4}
                delay={0.4}
                duration={0.7}
                initialX={0}
                initialY={-10}
              >
                <Text color="brand.tan.ulight" fontWeight="bold" fontSize="xl">
                  Minimalinvasive Behandlungen
                </Text>
              </FadeInAnimation>
              <FadeInAnimation
                threshold={0.4}
                delay={0.4}
                duration={0.7}
                initialX={0}
                initialY={-10}
              >
                <Text
                  color="brand.tan.ulight"
                  fontWeight="normal"
                  fontSize={{ base: "4xl", lg: "4xl" }}
                >
                  Hyaluron
                </Text>
              </FadeInAnimation>

              <FadeInAnimation
                threshold={0.4}
                delay={1}
                duration={0.7}
                initialX={0}
                initialY={10}
              >
                <Text
                  color="brand.tan.ulight"
                  mb={12}
                  maxW={{ base: "100%", lg: "100%" }}
                  fontSize="xl"
                >
                  Der Filler für Ihre Ausstrahlung
                </Text>

                <Button
                  mt={4}
                  onClick={() => setToggleL(true)}
                  variant="solid"
                  color="brand.violet.primary"
                  bg="brand.white"
                >
                  mehr
                </Button>
              </FadeInAnimation>
            </Box>
          </ImgTextModule>
        </GridItem>

        <GridItem colSpan="6" mb={8}>
          <ImgTextModule
            h="100%"
            src={"208529.mp4"}
            delay={0.3}
            duration={0.5}
            threshold={0.3}
            initialY={10}
            initialX={10}
            video={true}
            mediaOpacity={0.9}
            bgColor="brand.gray.vdark"
          >
            <Box p={{ base: 0, lg: "8" }}>
              <FadeInAnimation
                threshold={0.4}
                delay={0.4}
                duration={0.7}
                initialX={0}
                initialY={-10}
              >
                <Text color="brand.tan.ulight" fontWeight="bold" fontSize="xl">
                  Minimalinvasive Behandlungen
                </Text>
              </FadeInAnimation>
              <FadeInAnimation
                threshold={0.4}
                delay={0.4}
                duration={0.7}
                initialX={0}
                initialY={-10}
              >
                <Text
                  color="brand.white"
                  fontWeight="normal"
                  fontSize={{ base: "4xl", lg: "4xl" }}
                >
                  Botulinum
                </Text>
              </FadeInAnimation>

              <FadeInAnimation
                threshold={0.4}
                delay={1}
                duration={0.7}
                initialX={0}
                initialY={10}
              >
                <Text
                  color="brand.tan.ulight"
                  mb={12}
                  maxW={{ base: "100%", lg: "100%" }}
                  fontSize="xl"
                ></Text>

                <Button
                  mt={4}
                  onClick={() => setToggleR(true)}
                  variant="solid"
                  color="brand.tan.primary"
                  bg="brand.white"
                >
                  mehr
                </Button>
              </FadeInAnimation>
            </Box>
          </ImgTextModule>
        </GridItem>
      </Grid>
      <Modal2 isToggled={isToggledR} setToggle={setToggleR}></Modal2>
      <Modal1 isToggled={isToggledL} setToggle={setToggleL}></Modal1>
    </>
  )
}

export default TwoElementsBoHu
