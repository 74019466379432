import { Box, Button, Text, Heading } from "@chakra-ui/react"
import { Link } from "gatsby"
import { motion, AnimatePresence } from "framer-motion"
import React from "react"

export const Modal1 = ({ isToggled, setToggle, children }) => {
  return (
    <AnimatePresence>
      {isToggled && (
        <motion.div
          position="relative"
          top="250px"
          left="25%"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          mt={"25vh"}
        >
          <Box
            w={{ base: "80vw", md: "50vw" }}
            mt={"35vh"}
            as={motion.div}
            position="fixed"
            zIndex="99999"
            initial={{ y: 50, scale: 0.2 }}
            animate={{ y: 0, scale: 1 }}
            exit={{ y: -50, scale: 0.1 }}
            p={8}
            top={"0"}
            boxShadow="base"
            bg={"#ffffff"}
            borderRadius="11px"
          >
            <Heading color="brand.blue">Hayluron</Heading>
            <Text>
              Hyaluron ist ein körpereigener Stoff, der künstlich nachproduziert
              werden kann, und durch eine Quervernetzung verzögert vom Körper
              abgebaut wird. Die Haltbarkeit des Volumeneffektes liegt in der
              Regel zwischen sechs und neun Monaten.
              <br />
              Hyaluron induziert die Collagen- und Elastinsynthese und erhöht
              die Wasserspeicherkapazität der Haut.
              <br />
              Bei regelmäßiger Anwendung entsteht ein kumulativer – also ein
              länger anhaltender – Effekt. Bei Kombinationspräparaten (z.B. mit
              Hydroxylapatit) liegt die Wirksamkeit des Effektes bei bis 18
              Monaten. Hier jedoch muss bedacht, dass nicht alle Gesichtspartien
              dafür geeignet sind.
            </Text>

            <Button
              mt={12}
              px={6}
              pt={3}
              pb={4}
              fontSize="xs"
              textAlign="center"
              variant={"solid"}
              onClick={() => setToggle(false)}
            >
              Schliessen
            </Button>
          </Box>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
